var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('filter-slot',{attrs:{"filter-principal":_vm.filterPrincipal,"filter":_vm.filter,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":_vm.refresh},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"refSettingsList",attrs:{"slot":"table","small":"","no-provider-filtering":"","items":_vm.getSellers,"fields":_vm.visibleFields,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","sticky-header":"70vh","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(status_session)",fn:function(data){return [(data.item.status_session === 1)?_c('span',[_c('feather-icon',{staticStyle:{"fill":"#00d25b","color":"#00d25b"},attrs:{"icon":"CircleIcon","size":"17"}}),_vm._v(" Active ")],1):_vm._e(),(data.item.status_session === 2)?_c('span',[_c('feather-icon',{staticStyle:{"fill":"#ff9f43","color":"#ff9f43"},attrs:{"icon":"CircleIcon","size":"17"}}),_vm._v(" Busy ")],1):_vm._e(),(data.item.status_session === 3)?_c('span',[_c('feather-icon',{staticStyle:{"fill":"#fc424a","color":"#fc424a"},attrs:{"icon":"CircleIcon","size":"17"}}),_vm._v(" Away ")],1):_vm._e(),(
                data.item.status_session === 4 ||
                data.item.status_session === 0
              )?_c('span',[_c('feather-icon',{staticStyle:{"fill":"#82868b","color":"#82868b"},attrs:{"icon":"CircleIcon","size":"17"}}),_vm._v(" Offline ")],1):_vm._e()]}},{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(data.item.created_at))+" ")]}},{key:"cell(permission)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center",attrs:{"b-row":""}},[_c('div',{attrs:{"b-col":""}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(data.item.type_pending),expression:"data.item.type_pending"}],staticClass:"form-control",staticStyle:{"font-size":"11px","width":"110%"},attrs:{"disabled":_vm.disabledPermission(data)},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(data.item, "type_pending", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.onChangeSeniority(data.item)}],"input":function (e) {
                      _vm.onChangeSelect(e, data.item);
                    },"click":function (e) {
                      _vm.onChangeSelect(e, data.item);
                    }}},_vm._l((_vm.options),function(option){return _c('option',{key:option.value,domProps:{"value":option.value,"selected":option.value == data.item.type_pending}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)])])]}},{key:"cell(typesenior)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.typesenior)+" ")]}},{key:"cell(status_permission_seller)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center align-v-center"},[(
                  data.item.status === 'DENIED' ||
                  data.item.status === 'CANCELED' ||
                  data.item.status === 'APPROVED' ||
                  data.item.status === 'PENDING'
                )?_c('b-badge',{staticClass:"text-center w-100",class:data.item.status === 'DENIED' ||
                  data.item.status === 'CANCELED'
                    ? 'bg-light-danger'
                    : data.item.status === 'APPROVED'
                    ? 'bg-light-success'
                    : 'bg-light-warning',attrs:{"pill":""}},[_vm._v(" "+_vm._s(data.item.status)+" ("+_vm._s(data.item.typesenior_pending)+") ")]):_vm._e()],1)]}}])})]},proxy:true}])})],1),(_vm.modalTrackingState)?_c('modal-tracking',{attrs:{"dataItem":_vm.dataItem},on:{"hideModal":function($event){_vm.modalTrackingState = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }