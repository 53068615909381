export default [
    {
      key: "id",
      label: "Id",
      visible: false,
    },
    {
      key: "date",
      label: "Date",
      visible: true,
    },
    {
      key: "user",
      label: "User",
      visible: true,
    },
    {
      key: "status_session",
      label: "Status session",
      visible: true,
    },
    {
      key: "typesenior",
      label: "Current Level",
      visible: true,
    },
    {
      key: "status_permission_seller",
      label: "Status",
      visible: true,
      thClass: "text-center",
    }
  ];
  